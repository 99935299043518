'use client'
import { SessionProvider } from 'next-auth/react'
import { Suspense } from 'react'
import { SWRConfig } from 'swr'
import { ToastProvider } from '@/components/common/toast';
import { DesignSystemProvider } from '@planda/design-system'
import DndWrapper from '@/components/dnd/DndWrapper'
import Contexts from '@/layouts/Contexts'
import StoreProvider from '@/app/StoreProvider'

type Props = {
    children?: React.ReactNode
}

export const ClientProviders = ({ children }: Props) => {
    return (
        // <StitchesRegistry>
        <SWRConfig value={{
            onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                // Never retry on 404.
                if (error.status === 404) return

                // Only retry up to 10 times.
                if (retryCount >= 3) return

                // Retry after 5 seconds.
                setTimeout(() => revalidate({ retryCount }), 5000)
            },
        }} >
            <SessionProvider>
                <StoreProvider>
                    {/* <TooltipProvider> */}
                    <DesignSystemProvider>
                        <ToastProvider duration={3000} swipeDirection="right">
                            <DndWrapper>
                                {/* <ErrorBoundary> */}
                                <Contexts>
                                    <Suspense>{children}</Suspense>
                                </Contexts>
                                {/* </ErrorBoundary> */}
                            </DndWrapper>
                        </ToastProvider>
                    </DesignSystemProvider>
                    {/* </TooltipProvider> */}
                </StoreProvider>
            </SessionProvider>
        </SWRConfig>
        // </StitchesRegistry>
    )
}